import React, { useState, useEffect } from 'react';
import { FaRegStickyNote } from 'react-icons/fa'; // Ícones
import AOS from 'aos'; // Importando AOS
import 'aos/dist/aos.css'; // Importando o CSS do AOS

export default function Tutorial() {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inicializa o AOS com animações de 1000ms
  }, []);

  return (
    <section id="tutorial" className="bg-white text-white py-8" data-aos="fade-up">
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-icBackgroundGray mb-6" data-aos="fade-down">
          Dicas rápidas para impressão lambe lambe
        </h1>
        <p className="text-lg text-center text-indigo-500 mb-8" data-aos="fade-left">
          Conteúdo via{' '}
          <a href="https://lambesbrasil.com.br" className="text-indigo-800 hover:text-icBackgroundPink hover:underline">
            lambesbrasil.com.br
          </a>
        </p>

        {/* Papel Section */}
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-8" data-aos="fade-up">
          <h2 className="text-2xl font-semibold text-indigo-400 mb-4 flex items-center">
            <FaRegStickyNote className="mr-2 text-indigo-400" /> Papel
          </h2>
          <p className="text-lg text-gray-300 mb-4">
            
            Papel sulfite é comumente utilizado em escritórios e gráficas. Talvez seja o papel mais simples de se fazer os lambes criados digitalmente (imagem ou texto), justamente por ser amplamente usado nestes locais - e em todo território nacional. Além disso, possui enorme variedade de tamanhos padrão e fáceis de encontrar (A4, A3, A2, A1…), vendidos em resmas ou em bobinas. 
          </p>
          <p className="text-lg text-gray-300 mb-4">
            Em geral, o papel ideal para lambes é o de gramatura baixa, como o jornal ou o papel seda. <br></br>Para grandes quantidades, o papel offset também pode ser usado.
          </p>
        </div>

        {/* Cola Section */}
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-8" data-aos="fade-up">
          <h2 className="text-2xl font-semibold text-indigo-400 mb-4 flex items-center">
            <FaRegStickyNote className="mr-2 text-indigo-400" /> Cola
          </h2>
          <p className="text-lg text-gray-300 mb-4">
            A cola branca é a que mais conhecemos desde o ensino fundamental. O PVA é a sigla para Acetato de Polivinilo, descoberto na Alemanha pelo Dr. Fritz Klatte em 1912. Esse mesmo tio químico também recebeu o certificado para o patenteamento do Policloreto de Vinil, popularmente conhecido como PVC.
          </p>

          <div className="mb-4" data-aos="fade-right">
            <h4 className="text-lg font-semibold text-indigo-300 mb-2">Ingredientes:</h4>
            <ul className="list-disc pl-6 text-lg text-gray-300">
              <li>Cola Branca PVA</li>
              <li>Água</li>
            </ul>
          </div>

          <div className="mb-4" data-aos="fade-right">
            <h4 className="text-lg font-semibold text-indigo-300 mb-2">Modo de preparo:</h4>
            <ol className="list-decimal pl-6 text-lg text-gray-300">
              <li>1/3 de cola branca para 2/3 de água: não tem mistério, é só misturar.</li>
              <li>
                Deixe que fique com coloração de leite de coco, com consistência não muito aguada, mas também não muito concentrada.
              </li>
              <li>Algumas marcas populares: TEK Bond, Cascola, Leo Madeiras, Tenaz.</li>
            </ol>
          </div>

          <p className="text-lg text-red-400 font-semibold mb-4" data-aos="fade-left">
            IMPORTANTE: A quantidade de cola pode variar de acordo com a qualidade da cola. 
          <br></br>Caso a mistura fique muito aguada e o grude não funcione tanto, vale adicionar mais cola.
          </p>
        </div>
      </div>
    </section>
  );
}
