import React from "react";

const Project = () => {
  return (
    <section
      id="projeto"
      className="bg-icBackgroundPink py-16 flex flex-col justify-center"
    >
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto text-lg lg:text-xl text-white">
          {/* Título da seção */}
          <h2 className="max-w-6xl text-5xl font-semibold text-white mb-6">
            Sobre
          </h2>

          {/* Texto do projeto */}
          <p className="mb-6">
          Este projeto é o trabalho de conclusão do curso de Design Gráfico e Digital da Universidade da Região de Joinville - Univille. Ele representa não apenas uma tentativa de ação, mas também um esforço profundamente pessoal e autoral. Como projeto final, buscamos desenvolver uma iniciativa que o mercado profissional não nos permite explorar. Percebemos, assim, que o tema central não poderia ser outro: o design aqui se torna a ferramenta para amplificar as vozes femininas silenciadas.
          </p>
          <p className="mb-6">
          Invisíveis Comuns é uma jornada visual que explora as batalhas silenciosas e frequentemente invisíveis enfrentadas pelas mulheres. O título traz à tona a ironia dolorosa de como essas lutas são, paradoxalmente, comuns e invisíveis ao mesmo tempo. Ele reflete sobre o cotidiano feminino, onde opressões e adversidades se tornam tão rotineiras que, muitas vezes, são ignoradas, negadas ou aceitas como parte do "normal".
          </p>
          <p className="mb-6">
          O projeto é uma ode às vozes não ouvidas e às experiências marginalizadas. Dividido em três coleções, ele explora a complexidade e profundidade das vivências femininas: cada coleção mergulha em diferentes aspectos da jornada das mulheres, desde a violência e a opressão até a busca pela libertação. Invisíveis Comuns é um manifesto em constante construção, um movimento visual de protesto, reflexão e celebração da força daquelas que, mesmo na invisibilidade, persistem.
          </p>

          {/* Fonte */}
          {/* <p className="text-sm text-white mt-8">
            Fonte: [Nome da Fonte ou Link para a fonte]
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default Project;
