import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaCircle } from 'react-icons/fa'; // Importando o ícone de download

const Downloads = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const files = [
    {
      id: 1,
      title: '(A) VIOLÊNCIA',
      description: [
        'Esta série expõe os sinais muitas vezes ignorados de abuso. O objetivo é escancarar a violência que tantas vezes se oculta nas sombras do cotidiano, trazendo à luz a dor de quem é silenciada por uma sociedade que ainda normaliza a violência de gênero.',
        'Cada cartaz é um grito por justiça, uma narrativa visual que convida o observador a confrontar realidades muitas vezes ‘’invisíveis’’. É uma lembrança da urgência de romper o ciclo da violência, reconhecendo as agressões para promover a mudança.',
        'Apesar das duras verdades aqui expostas, é fundamental reconhecer os avanços em legislação e acolhimento às vítimas. A luta continua e, com ela, a esperança de um futuro mais seguro para todas as mulheres.',
      ],
      fileName: 'https://invisiveiscomuns.s3.us-west-1.amazonaws.com/Cole%C3%A7%C3%A3o+(A)+Liberta%C3%A7%C3%A3o.pdf',
      imageUrl: '/img/downloads/violencia.png',
      images: [
        '/img/collections/1.violencia/1.webp',
        '/img/collections/1.violencia/2.webp',
        '/img/collections/1.violencia/3.webp',
        '/img/collections/1.violencia/4.webp',
        '/img/collections/1.violencia/5.webp',
        '/img/collections/1.violencia/6.webp',
        '/img/collections/1.violencia/7.webp',
        '/img/collections/1.violencia/8.webp',
      ],
      fileSize: '292 MB'
    },
    {
      id: 2,
      title: '(A) OPRESSÃO',
      description: [
        'Esta coleção explora as pressões sutis e, ao mesmo tempo, constantes que as mulheres enfrentam em suas vivências cotidianas, trazendo à tona normas sociais, estéticas e comportamentais que frequentemente agem como barreiras invisíveis, limitando suas escolhas.',
        'A série propõe um olhar crítico sobre os padrões impostos e as expectativas culturais que, disfarçados de "normalidade", acabam aprisionando as mulheres em estereótipos e papéis pré-definidos. A coleção busca evidenciar essas amarras invisíveis e, assim, provocar o observador a questionar os sistemas de controle e expectativas que naturalizamos.',
        'Ao expor essas realidades, a série visa sensibilizar e abrir caminho para um diálogo sobre o impacto das opressões na vida de todas as mulheres.',
      ],
      fileName: 'https://invisiveiscomuns.s3.us-west-1.amazonaws.com/Cole%C3%A7%C3%A3o+(A)+Opress%C3%A3o.pdf',
      imageUrl: '/img/downloads/opressao.png',
      images: [
        '/img/collections/2.opressao/1.webp',
        '/img/collections/2.opressao/2.webp',
        '/img/collections/2.opressao/3.webp',
        '/img/collections/2.opressao/4.webp',
        '/img/collections/2.opressao/5.webp',
        '/img/collections/2.opressao/6.webp',
        '/img/collections/2.opressao/7.webp',
        '/img/collections/2.opressao/8.webp',
      ],
      fileSize: '219 MB'
    },
    {
      id: 3,
      title: '(A) LIBERTAÇÃO',
      description: [
        'A terceira e última série é uma celebração da força e da capacidade de transformação de cada mulher. Ela reconhece o poder da autoestima e da união, que permitem às mulheres transcender as opressões e, através da coletividade e da solidariedade, construir novos caminhos para a liberdade.',
        'A coleção enfatiza a importância da luta contínua e da perseverança para romper com os padrões, mostrando que a verdadeira libertação é um processo contínuo, feito de coragem e união, propondo ao observador enxergar o empoderamento feminino não como um ato isolado, mas como um movimento coletivo.',
        'Cada cartaz é uma expressão de empoderamento, refletindo o papel essencial do apoio mútuo e da força coletiva para a transformação e construção de uma sociedade mais justa.',
      ],
      fileName: 'https://invisiveiscomuns.s3.us-west-1.amazonaws.com/Cole%C3%A7%C3%A3o+(A)+Viol%C3%AAncia.pdf',
      imageUrl: '/img/downloads/libertacao.png',
      images: [
        '/img/collections/3.libertacao/1.webp',
        '/img/collections/3.libertacao/2.webp',
        '/img/collections/3.libertacao/3.webp',
        '/img/collections/3.libertacao/4.webp',
        '/img/collections/3.libertacao/5.webp',
        '/img/collections/3.libertacao/6.webp',
        '/img/collections/3.libertacao/7.webp',
        '/img/collections/3.libertacao/8.webp',
      ],
      fileSize: '180 MB'
    },
  ];

  useEffect(() => {
    // Bloquear o scroll da página inteira ao abrir o modal
    if (showModal) {
      document.documentElement.style.overflow = 'hidden'; // Bloqueia o scroll do <html>
      document.body.style.overflow = 'hidden'; // Bloqueia o scroll do <body>
    } else {
      document.documentElement.style.overflow = 'auto'; // Libera o scroll do <html>
      document.body.style.overflow = 'auto'; // Libera o scroll do <body>
    }

    // Adiciona o listener de teclado para fechar o modal com 'Esc'
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeModal(); // Fecha o modal
      }
    };

    if (showModal) {
      window.addEventListener('keydown', handleEscKey); // Adiciona o listener
    } else {
      window.removeEventListener('keydown', handleEscKey); // Remove o listener
    }

    AOS.init({ duration: 1000, once: true });

    // Cleanup para garantir que o scroll seja liberado ao fechar o modal
    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', handleEscKey); // Remove o listener ao desmontar
    };
  }, [showModal]);

  const openModal = (file) => {
    setCurrentFile(file);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentFile(null);
  };

  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '15px',
        },
      },
    ],
  };

  const carouselSettingsDefault = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '15px',
        },
      },
    ],
  };

  return (
    <section id="collections" className="bg-icBackgroundGray py-12 px-0 lg:px-4">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold text-white mb-6 text-center">Coleções</h2>
        
        <p className="mt-6 text-xl text-white mb-6 text-center" data-aos="fade-left">
          Conheça as três coleções temáticas que dão vida ao projeto
        </p>

        {/* Carrossel */}
        <div className="max-w-xl mx-auto">
          <Slider {...carouselSettingsDefault}>
            {files.map((file) => (
              <div key={file.id} className="p-6">
                <div
                  className="bg-gray-200 p-4 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 text-center"
                  data-aos="fade-up"
                >
                  <img
                    src={file.imageUrl}
                    alt={`Thumbnail de ${file.title}`}
                    className="w-full object-cover rounded-xl mb-4"
                  />
                  <h3 className="text-xl font-semibold text-icBackgroundPink mb-3">{file.title}</h3>
                  <p className="text-sm mb-4">{file.description[0]}</p>
                  <div className="flex flex-col sm:flex-row justify-center gap-4">
                    <button
                      onClick={() => openModal(file)}
                      className="text-white bg-icBackgroundGray py-1 px-6 rounded-full text-sm border-2 border-icBackgroundGray hover:bg-black hover:text-white transition duration-300"
                    >
                      Ler mais
                    </button>

                    {/* <a
                      href={file.fileName}
                      download
                      className="text-black py-1 px-6 rounded-full text-sm border-2 border-icBackgroundGray hover:bg-icBackgroundGray hover:text-white transition duration-300"
                    >
                      Baixar Coleção
                      <span className="ml-2 text-gray-500">{file.fileSize}</span>
                    </a> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Modal */}
        {showModal && currentFile && (
          <div className="collection-modal fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 ">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-2/3 lg:w-3/4 relative max-h-[90vh] overflow-y-auto">
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-gray-600 text-xl"
              >
                &times;
              </button>

              {/* Layout adaptativo */}
              <div className="flex flex-col lg:flex-row lg:space-x-6 mt-6 md:mt-0">
                {/* Carrossel de imagens */}
                <div className="lg:w-1/2 mb-6 ">
                  <Slider {...carouselSettings}>
                    {currentFile.images.map((img, index) => (
                      <div key={index} className="flex justify-center items-center h-full">
                        <img
                          src={img}
                          alt={`Imagem ${index + 1} de ${currentFile.title}`}
                          className="rounded-lg object-contain w-11/12 lg:w-10/12 mx-auto max-h-[calc(90vh-12rem)] lg:max-h-[calc(90vh-6rem)]"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* Texto */}
                <div className="lg:w-1/2">
                  <h3 className="text-2xl font-bold text-gray-800 mb-4">
                    {currentFile.title}
                  </h3>
                  <div className="space-y-4">
                    {currentFile.description.map((text, index) => (
                      <p key={index} className="text-gray-700">
                        {text}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


      </div>
    </section>
  );
};

export default Downloads;
