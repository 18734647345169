import React, { useEffect } from "react";
import AOS from 'aos';  // Importando AOS
import 'aos/dist/aos.css';  // Importando o CSS do AOS

const people = [
  {
    name: 'Georgia Paes',
    role: 'Graduanda de Design',
    imageUrl: '/img/team/georgia.jpg',
    email: 'georgiapaes@live.com',
  },
  {
    name: 'Carolaine Ribeiro',
    role: 'Graduanda de Design',
    imageUrl: '/img/team/carolaine.jpg',
    email: 'carolaineribeirog@gmail.com',
  },
  {
    name: 'Adriane Shibata',
    role: 'Professora e Orientadora ',
    imageUrl: '/img/team/adriane.jpg',
    email: 'adriane.shibata@univille.br',
  },
]

export default function Team() {
  useEffect(() => {
    AOS.init({ duration: 1000 });  // Inicializa o AOS com animações de 1000ms
  }, []);

  return (
    <section id="contato">
      <div className="bg-white py-24 sm:py-12">
        <div className="mx-auto max-w-6xl px-6 lg:px-8">
          {/* Texto de apresentação da equipe */}
          <div className="text-center mb-16">
            <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up">
              Contato
            </h2>
            {/* <p className="mt-6 text-2xl text-gray-600 mb-20" data-aos="fade-left">
              Um time de vlaisjdo aisjd oaisjdo iajsdoi jasoidj iaosjd<br></br> ijasoidj oiasjd oiasdoi jasoidj aosijd oiasjdoi asdoij
            </p> */}
          </div>

          {/* Lista de pessoas */}
          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3 justify-center">
            {people.map((person, index) => (
              <li key={person.name} className="flex flex-col items-center text-center" data-aos="fade-up" data-aos-delay={index * 200}>
                {/* Imagem da pessoa */}
                <img alt={person.name} src={person.imageUrl} className="h-64 w-64 rounded-full object-cover" />
                <div className="mt-4">
                  {/* Nome e Cargo */}
                  <h3 className="text-xl font-semibold text-gray-900">{person.name}</h3>
                  <p className="mt-2 text-sm font-medium">{person.role}</p>
                  {/* <p className="text-sm text-gray-500">{person.roleDescription}</p> */}
                  {/* Informações adicionais */}
                  <div className="mt-2">
                    {/* <a href={`https://www.instagram.com/${person.instagram}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:underline">{person.instagram}</a>
                    <br /> */}
                    <a href={`mailto:${person.email}`} className="text-indigo-600 hover:underline">{person.email}</a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
