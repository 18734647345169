import React, { useState } from "react";

export default function Header() {
  // Estado para controlar se o menu está aberto ou fechado
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Função para alternar o estado do menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-icBackgroundPink">
      <nav className="border-gray-200">
        <div className="container mx-auto px-4">
        <div className="max-w-6xl flex items-center justify-between mx-auto py-4">
          {/* Logo à esquerda */}
          <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img
              src="/logo.png" // Caminho para sua logo
              className="h-16"
              alt="Logo"
            />
          </a>

          {/* Menu à direita */}
          <div className="hidden md:flex md:space-x-8">
            <ul className="flex space-x-8 text-white text-xl">
              <li>
                <a href="#projeto" className="hover:underline">
                  Sobre
                </a>
              </li>
              <li>
                <a href="#collections" className="hover:underline">
                  Coleções
                </a>
              </li>
              <li>
                <a href="#downloads" className="hover:underline">
                  Downloads
                </a>
              </li>
              <li>
                <a href="#tutorial" className="hover:underline">
                  Tutorial
                </a>
              </li>
              <li>
                <a href="#contato" className="hover:underline">
                  Contato
                </a>
              </li>
            </ul>
          </div>

          {/* Menu responsivo (hamburguer) */}
          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-icBackgroundPink focus:outline-none focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-icBackgroundGray dark:focus:ring-gray-600"
            onClick={toggleMenu} // Alterna o estado de abertura do menu
          >
            <span className="sr-only">Abrir menu</span>
            {/* Exibe o ícone do hambúrguer quando o menu está fechado e X quando aberto */}
            {isMenuOpen ? (
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2 2l13 13M2 14L15 2"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Menu mobile */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:hidden w-full bg-icBackgroundPink p-4`}
        >
          <ul className="space-y-4 text-white text-xl">
            <li>
              <a href="#projeto" className="hover:underline">
                Sobre
              </a>
            </li>
            <li>
              <a href="#collections" className="hover:underline">
                Coleções
              </a>
            </li>
            <li>
              <a href="#downloads" className="hover:underline">
                Downloads
              </a>
            </li>
            <li>
              <a href="#tutorial" className="hover:underline">
                Tutorial
              </a>
            </li>
            <li>
              <a href="#contato" className="hover:underline">
                Contato
              </a>
            </li>
          </ul>
        </div>
        </div>
      </nav>
    </header>
  );
}
