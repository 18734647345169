import React from 'react';
import { Helmet } from 'react-helmet'; // Biblioteca para manipular o <head> do documento.

const SEO = () => {
  return (
    <Helmet>
      {/* Meta tags básicas */}
      <title>Invisíveis Comuns</title>
      <meta name="description" content="Um manifesto visual das lutas femininas." />
      <meta name="keywords" content="palavras-chave, para, SEO" />
      <meta name="author" content="Georgia Paes, Carolaine Ribeiro" />
      <meta name="robots" content="index, follow" /> {/* Controla a indexação da página */}
      
      {/* Open Graph (para redes sociais) */}
      <meta property="og:title" content="Invisíveis Comuns" />
      <meta property="og:description" content="Um manifesto visual das lutas femininas." />
      <meta property="og:image" content="ogImage.png" />
      <meta property="og:url" content="https://www.invisiveiscomuns.com/" />
      <meta property="og:type" content="website" />
      
      {/* Twitter Card */}
      <meta name="twitter:title" content="Invisíveis Comuns" />
      <meta name="twitter:description" content="Um manifesto visual das lutas femininas." />
      <meta name="twitter:image" content="ogImage.png" />
      <meta name="twitter:card" content="summary_large_image" />
      
      {/* Favicons */}
      <link rel="icon" href="/path/to/favicon.ico" />
      <link rel="apple-touch-icon" href="/path/to/apple-icon.png" />
    </Helmet>
  );
};

export default SEO;