// src/App.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';  // Importando o CSS do AOS
import Header from './components/Header';
import Project from './components/Project';
import Team from './components/Team';
import HeroSection from './components/HeroSection';
import Tutorial from './components/Tutorial';
import Collections from './components/Collections';
import Downloads from './components/Downloads';
import Footer from './components/Footer';
import SEO from './components/SEO';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';



const App = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-5GLBGHXP', // Substitua pelo seu ID do GTM
    };
    
    // Inicializa o AOS com a duração das animações
    AOS.init({ duration: 1000 });
    ReactGA.initialize('G-Y7SBD0XL5K');  // Substitua pelo seu ID de medição
    ReactGA.send('pageview');
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="font-sans">
       <SEO />
      <Header data-aos="fade-down" id="header" />
      <HeroSection data-aos="fade-up" id="hero" />
      <Project />
      <Collections data-aos="fade-up" id="collections" />
      <Downloads data-aos="fade-up" id="downloads" />
      <Tutorial data-aos="fade-left" id="tutorial" />
      <Team data-aos="fade-right" id="contato" />
      <Footer data-aos="fade-up" id="footer" />
    </div>
  );
};

export default App;
