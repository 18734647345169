// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="bg-icBackgroundPink flex flex-col items-center justify-center py-10 bg-gray-100">
        <div className="flex items-center space-x-8 mb-4">
          {/* Logo 1 */}
          <img
            src="/img/footer/projeto.png" // Substitua pelo URL do seu logo
            alt="Logo do projeto"
            className="h-24 w-24"
          />
          
          {/* Logo 2 */}
          <img
            src="/img/footer/univille.png" // Substitua pelo URL do seu logo
            alt="Logo da instituição Univille"
            className="h-24 w-24"
          />
        </div>

        <p className="text-center text-sm text-white leading-loose md:text-xl lg:text-2xl ">
          Projeto de conclusão de curso em Design<br></br>Universidade da Região de Joinville - Univille
        </p>
      </div>
      <div className="bg-icBackgroundGray text-white p-6 text-center">
        <p className="flex justify-center items-center">Desenvolvido por <b className="text-brilhante">573 Labs.</b></p>
      </div>
    </footer>
  );
};

export default Footer;
