// src/components/HeroSection.js
import React from 'react';

const HeroSection = () => {
  return (
    <section id="home" className="min-h-screen bg-cover bg-center text-white flex flex-col justify-center" style={{ backgroundImage: "url('/capa.png')" }}>
      {/* Logo do projeto */}
      {/* <div className="max-w-screen-xl flex flex-col items-left justify-center md:w-96"> */}
      <div className="container mx-auto px-4">
      <div className="max-w-6xl mx-auto text-lg lg:text-2xl text-white">
        <img src="/logo-projeto.png" alt="Logo" className="w-96" />

         {/* Ícone de rolagem */}
        <a href="#projeto" className="flex justify-center items-center md:w-96">
          <div className="mt-8 text-3xl">
            {/* Icone de seta para rolar */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 text-white animate-bounce">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7 7 7-7" />
            </svg>
          </div>
        </a>
      </div>
      </div>
    </section>
  );
};

export default HeroSection;
