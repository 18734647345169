import React, { useState } from 'react';
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Downloads = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const [showOtherField, setShowOtherField] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    profession: '',
    email: '',
    purpose: '',
    other: ''
  });

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handlePurposeChange = (event) => {
    const value = event.target.value;
    setSelectedPurpose(value);
    setFormData(prevFormData => ({
      ...prevFormData,
      purpose: value
    }));
    setShowOtherField(value === '__other_option__');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Impede o envio padrão do formulário

    // Prepara os dados a serem enviados via GET
    const queryParams = new URLSearchParams({
      'entry.105044060': formData.name,
      'entry.1351406178': formData.profession,
      'entry.1252140217': formData.email,
      'entry.2128837545': formData.purpose,
      'entry.2128837545.other_option_response': formData.other,
    }).toString();

    try {
      // Envia os dados via GET para o Google Forms
      const response = await fetch(`https://docs.google.com/forms/d/e/1FAIpQLSfSJEqBlmLfQwlhuOBDnbtp23-iJO1UaFDEwLwlHwK2xfview/formResponse?${queryParams}`, {
        method: 'GET',
      });

      if (response.status == 200) {
        // Se a requisição for bem-sucedida, mostra a tela de sucesso
        setIsFormSubmitted(true);
      } else {
        // Caso haja algum erro, mostre um erro
        alert("Erro ao enviar os dados. Tente novamente.");
      }
    } catch (error) {
      //alert("Erro na conexão. Tente novamente.");
      setIsFormSubmitted(true);
    }
  };

  return (
    <section id="downloads" className="bg-icBackgroundPink py-8">
      {/* Título Downloads no topo */}
      <h2 className="text-3xl font-semibold text-white text-center mb-0">
        Downloads
      </h2>
  
      <div className="flex flex-col justify-center py-12" data-aos="fade-up">
        <div className="container mx-auto flex flex-col lg:flex-row-reverse items-center overflow-hidden max-w-6xl px-4 lg:px-8">
          {/* Lado esquerdo - Imagem */}
          <div className="w-full lg:w-1/2 flex items-center justify-center lg:order-1">
            <img
              src="img/tutorial/tutorial.jpg" // Substitua pela URL da sua imagem
              alt="Imagem de exemplo"
              className="w-full rounded-t-xl lg:rounded-none lg:rounded-l-xl shadow-lg h-auto object-cover h-[400px] lg:h-[800px]"
            />
          </div>
  
          {/* Lado direito - Formulário */}
          <div className="w-full lg:w-1/2 bg-white rounded-b-xl lg:rounded-none lg:rounded-r-xl shadow-lg p-6 flex flex-col justify-around lg:h-[800px]">
            {!isFormSubmitted ? (
              // Formulário
            <div>
            <h3 className="text-3xl font-semibold text-icBackgroundPink mb-4 text-center md:text-left">
              Vai imprimir?
            </h3>
            <p className="text-base xl:text-lg text-gray-700 mb-6 text-center md:text-left">
              Baixe as coleções em alta qualidade e{' '}
              <a
                href="#tutorial"
                className="text-icBackgroundPink hover:text-pink-700 transition duration-300"
              >
                veja dicas rápidas para impressão lambe lambe.
              </a>
              {' '}Mas antes, nos ajude a entender um pouco mais sobre você*:
            </p>
  
            
              <form onSubmit={handleFormSubmit} className="flex flex-col space-y-4">
                {/* Nome */}
                <div className="mb-2">
                  <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
                    Nome:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring-2 focus:ring-icBackgroundPink"
                    placeholder="Digite seu nome"
                    required
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </div>

                {/* E-mail */}
                <div className="mb-2">
                  <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                    E-mail:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring-2 focus:ring-icBackgroundPink"
                    placeholder="Digite seu e-mail"
                    required
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </div>
  
                {/* Profissão */}
                <div className="mb-2">
                  <label htmlFor="profession" className="block text-gray-700 font-medium mb-2">
                    Profissão:
                  </label>
                  <input
                    type="text"
                    id="profession"
                    name="profession"
                    className="w-full border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring-2 focus:ring-icBackgroundPink"
                    placeholder="Digite sua profissão"
                    required
                    value={formData.profession}
                    onChange={(e) => setFormData({ ...formData, profession: e.target.value })}
                  />
                </div>
  
  
                {/* Finalidade */}
                <div className="mb-2">
                  <label htmlFor="purpose" className="block text-gray-700 font-medium mb-2">
                    Finalidade:
                  </label>
                  <select
                    id="purpose"
                    name="purpose"
                    className="w-full border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring-2 focus:ring-icBackgroundPink"
                    value={selectedPurpose}
                    onChange={handlePurposeChange}
                    required
                  >
                    <option value="" disabled>
                      Selecione a finalidade
                    </option>
                    <option value="Uso acadêmico">Uso acadêmico</option>
                    <option value="Uso profissional">Uso profissional</option>
                    <option value="Intervenção urbana">Intervenção urbana</option>
                    <option value="__other_option__">Outro</option>
                  </select>
                </div>
  
                {/* Campo para "Outro" */}
                {showOtherField && (
                  <div className="mb-2">
                    <label htmlFor="other_option_response" className="block text-gray-700 font-medium mb-2">
                      Por favor, descreva:
                    </label>
                    <input
                      type="text"
                      id="other_option_response"
                      name="other_option_response"
                      className="w-full border border-gray-300 rounded-md p-2 text-gray-800 focus:outline-none focus:ring-2 focus:ring-icBackgroundPink"
                      placeholder="Descreva sua finalidade"
                      required
                      value={formData.other}
                      onChange={(e) => setFormData({ ...formData, other: e.target.value })}
                    />
                  </div>
                )}
  
                {/* Checkbox */}
                <div className="mb-6">
                    <span className="text-xs text-gray-700">
                      (*) Ao preencher este formulário, você concorda que os dados fornecidos poderão ser utilizados exclusivamente para fins de pesquisa, em conformidade com a Lei 13.709/2018 – Lei Geral de Proteção de Dados Pessoais.
                    </span>
                </div>
  
                {/* Botão de envio */}
                <div className="text-center">
                  <button
                    type="submit"
                    disabled={!isCheckboxChecked}
                    className={`py-2 px-6 rounded-full text-white transition duration-300 ${
                      isCheckboxChecked
                        ? 'bg-icBackgroundPink hover:bg-pink-700'
                        : 'bg-gray-400 cursor-not-allowed'
                    }`}
                  >
                    Baixar Coleção
                  </button>
                </div>
              </form>
              </div>
            ) : (
              // Mensagem de sucesso
              
              <div className="bg-white rounded-lg p-6 max-w-lg mx-auto text-center">
                {/* Ícone de sucesso */}
                <FaCheckCircle className="text-icBackgroundPink text-6xl mx-auto mb-4" />

                {/* Título Obrigadx */}
                <h3 className="text-3xl font-semibold text-icBackgroundPink mb-4">
                  Dados recebidos!
                </h3>

                {/* Mensagem de agradecimento */}
                <p className="text-gray-700 mb-6">
                  Obrigado por preencher o formulário. Clique no botão abaixo para baixar os cartazes.
                </p>

                {/* Botão para baixar cartazes */}
                <a
                  href="https://drive.google.com/file/d/1lYf1ydyiSM8hkX_m4gnyqZoXziQRHU7g/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  // className="inline-block bg-icBackgroundPink text-white py-2 px-6 rounded-full hover:bg-pink-700 transition duration-300"
                  className="flex items-center justify-center text-black w-auto py-2 px-6 rounded-full text-sm border-2 border-icBackgroundGray hover:bg-icBackgroundGray hover:text-white transition duration-300 mt-6"
                >
                  Baixar Coleção <FaCircle className="mx-2 text-gray-500 text-[0.3rem]" /><span className=" text-gray-500">246{' '}MB</span>
                </a>

                {/* Dica para impressão */}
                <p className="text-gray-700 mt-12">
                  Quer dicas para impressão lambe-lambe?

                  <a href="#tutorial" className="flex justify-center items-center">
                    <div className="mt-8 text-3xl">
                      {/* Icone de seta para rolar */}
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 text-icBackgroundPink animate-bounce">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7 7 7-7" />
                      </svg>
                    </div>
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}  

export default Downloads;
